<template>
  <div>
    <el-row style="margin-bottom: 12px; width: 100%" type="flex" justify="space-between">
      <el-col>
        <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :name="1" label="气象"></el-tab-pane>
          <el-tab-pane :name="2" label="水质水位"></el-tab-pane>
          <el-tab-pane :name="3" label="土壤墒情"></el-tab-pane>
          <el-tab-pane :name="4" label="虫情"></el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="6">
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
    </el-row>
    <qixiang :data="value" v-if="activeName == 1"></qixiang>
    <shuizhi :data="value" v-if="activeName == 2"></shuizhi>
    <turang :data="value" v-if="activeName == 3"></turang>
    <chongqing :data="value" v-if="activeName == 4"></chongqing>
    <!-- <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%;height: 100%;" src="@/assets/empty.jpeg" alt="" />
      </template>

      <el-table-column v-if="activeName != 4" prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column v-if="activeName != 4" prop="deviceCode" label="设备序列号"> </el-table-column>
      <el-table-column v-if="activeName != 4" prop="date" label="日期"> </el-table-column>

      <el-table-column v-if="activeName == 1" prop="airhumidity" label="空气湿度"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="airtemperature" label="空气温度"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="lightintensity" label="光照强度"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="soiltemperature" label="土壤温度"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="soilhumidity" label="土壤湿度"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="rainfall" label="降雨量"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="winddirection" label="风向"> </el-table-column>
      <el-table-column v-if="activeName == 1" prop="windpower" label="风速"> </el-table-column>

      <el-table-column v-if="activeName == 2" prop="temperature" label="水温"> </el-table-column>
      <el-table-column v-if="activeName == 2" prop="ph" label="PH"> </el-table-column>
      <el-table-column v-if="activeName == 2" prop="oxygen" label="溶氧"> </el-table-column>
      <el-table-column v-if="activeName == 2" prop="waterDepth" label="水位"> </el-table-column>

      <el-table-column v-if="activeName == 3" prop="soiltemperature" label="土壤温度"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soilhumidity" label="土壤湿度"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soiltemperature2" label="土壤温度2"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soilhumidity2" label="土壤湿度2"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soiltemperature3" label="土壤温度3"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soilhumidity3" label="土壤湿度3"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soiltemperature4" label="土壤温度4"> </el-table-column>
      <el-table-column v-if="activeName == 3" prop="soilhumidity4" label="土壤湿度4"> </el-table-column>

      <el-table-column v-if="activeName == 4" prop="statisNum" label="杀虫数"> </el-table-column>
      <el-table-column v-if="activeName == 4" prop="analysisDate" label="杀虫日期"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import { siqingHistory } from '@/api/api.js';
import qixiang from './qixiang.vue';
import shuizhi from './shuizhi.vue';
import turang from './turang.vue';
import chongqing from './chongqing.vue';
import dayjs from 'dayjs';
export default {
  components: {
    qixiang,
    shuizhi,
    turang,
    chongqing
  },
  data() {
    return {
      activeName: 1,
      options: [
        {
          value: 7,
          label: '近7日数据'
        },
        {
          value: 30,
          label: '近30日数据'
        }
      ],
      value: 7
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
