<template>
  <div class="">
    <div class="row">
      <div id="chongqingchar1" class="chart-item"></div>
      <div id="chongqingchar2" class="chart-item"></div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    data: 7
  },
  watch: {
    data: {
      handler(val) {
        this.startTime = dayjs()
          .subtract(val - 1, 'day')
          .format('YYYY-MM-DD');
        this.getData();
      },
      deep: true
    }
  },
  data() {
    return {
      startTime: '',
      endTime: ''
    };
  },
  created() {},
  mounted() {
    this.startTime = dayjs()
      .subtract(this.data - 1, 'day')
      .format('YYYY-MM-DD');
    this.endTime = dayjs().format('YYYY-MM-DD');
    this.getData();
  },
  methods: {
    getData() {
      postRequest('/api/iot/cq/cqTypeInfo', { startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart1(res);
        })
        .catch((error) => {});
      postRequest('/api/iot/cq/cqStaticInfo', { startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart2(res);
        })
        .catch((error) => {});
    },
    chart1(list) {
      let option = {
        color: ['#63A1FF'],
        tooltip: {
          trigger: 'axis'
        },
        title: [
          {
            text: `虫情种类近${this.data}日统计`
          }
        ],
        legend: {
          data: ['虫情种类']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return e.bugName;
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '虫情种类',
            data: list.map((e) => {
              return e.bugNum;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('chongqingchar1', option);
    },
    chart2(list) {
      let option = {
        color: ['rgba(255, 116, 116, 1)'],
        tooltip: {
          trigger: 'axis'
        },
        title: [
          {
            text: `杀虫数量近${this.data}日统计`
          }
        ],
        legend: {
          data: ['采集数量']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.analysisDate).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '采集数量',
            data: list.map((e) => {
              return e.statisNum;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 116, 116, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(255, 116, 116, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('chongqingchar2', option);
    },
    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.chart-item {
  width: 49%;
  height: 260px;
  background-color: rgba(248, 248, 249, 1);
  padding: 12px;
  box-sizing: border-box !important;
}
</style>
