<template>
  <div class="">
    <el-breadcrumb v-if="from == 'metting'" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/mettingManage' }" replace>会议管理</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-if="from == 'training'" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/trainingManage' }" replace>培训管理</el-breadcrumb-item>
      <el-breadcrumb-item>培训详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="height: 24px"></div>
    <div class="flex-row">
      <div>
        <img class="img" :src="detailInfo.titleImgUrl" />
      </div>

      <div class="detail-info">
        <div class="title">{{ detailInfo.title }}</div>
        <div class="sub-info">{{ detailInfo.serviceOrgName }}</div>
        <div class="sub-info">地址:{{ detailInfo.address }}</div>
        <div class="sub-info">会议时间： {{ detailInfo.startDate }}-{{ detailInfo.endDate }} </div>
        <div class="sub-info">报名时间： {{ detailInfo.applyStartDate }}-{{ detailInfo.applyEndDate }}</div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="详情" name="1"></el-tab-pane>
      <el-tab-pane :label="`已报名人数(` + applyList.length + `)`" name="2"></el-tab-pane>
      <!-- <el-tab-pane label="培训总结" name="3"></el-tab-pane> -->
    </el-tabs>
    <div v-if="activeName == '1'" v-html="detailInfo.content"></div>
    <el-table v-if="activeName == '2'" :data="applyList" height="250" border style="width: 100%">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="applyName" label="姓名"> </el-table-column>
      <el-table-column prop="applyPhone" label="手机号"> </el-table-column>
      <el-table-column prop="updateTime" label="时间"> </el-table-column>
    </el-table>
    <div class="zeongjie" v-if="activeName == '3'">
      <quill-editor class="editor" :options="editorOption" :content="content" @change="onEditorChange" />
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { serviceDetail, applyList, getRequest } from '@/api/api';
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '请输入正文'
      },
      content: '',
      richText: '',
      activeName: '1',
      tableData: [{ name: 1, phone: 1, data: '2022/01/01' }],
      detailInfo: {},
      from: '',
      applyList: []
    };
  },
  created() {},
  mounted() {
    let pathName = '培训详情';
    if (this.from == 'metting') {
      pathName = '会议详情';
    }

    this.$store.commit('set_breadList', [{ name: pathName }]);
    let { from = '', id } = this.$route.query;
    this.from = from;
    this.id = id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      let url = '';
      if (this.from == 'metting') {
        url = '/api/operate/govserv/meeting/view';
      } else {
        url = '/api/operate/govserv/training/view';
      }

      getRequest(url, { id: this.id })
        .then((e) => {
          this.detailInfo = e;
          this.getList();
        })
        .catch((error) => {});
    },
    getList() {
      let url = '';
      if (this.from == 'metting') {
        url = '/api/operate/govserv/meeting/apply/page';
      } else {
        url = '/api/operate/govserv/training/apply/page';
      }

      getRequest(url, { serviceId: this.id }).then((res) => {
        this.applyList = res.rows;
      });
    },
    onEditorChange(e) {
      this.richText = e;
    }
  }
};
</script>
<style scoped lang="scss">
.img {
  width: 200px;
  height: 200px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.detail-info {
  margin-left: 24px;

  .title {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }

  .sub-info {
    font-size: 12px;
    line-height: 20px;
  }
}

.editor {
  width: 100%;
  height: 300px;
}

.zeongjie {
  width: 100%;
}
</style>
