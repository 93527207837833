<template>
  <div class="page-container">
    <el-row :gutter="30">
      <el-col :span="6" v-for="item in landList" :key="item.id">
        <div class="item" @click="createOrEditLand(item)">
          <div class="item-wrap">
            <div class="img-wrap">
              <el-image v-if="!loadError" style="width: 100%" :src="item.remoteSenseImgSmallUrl" fit="contain" @error="imgError"></el-image>
              <el-image v-else style="width: 100%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="content-wrap">
              <div class="name one-hidden">{{ item.name }}</div>
              <div class="content-foot">
                <div class="foot-left align-center"> <img src="@/images/area-icon.png" alt="" />{{ formatPlantArea(item.area) }}亩 </div>
                <div class="foot-right align-center"><img src="@/images/date-icon.png" alt="" />{{ item.createTime | formatDate }}</div>
              </div>
            </div>
          </div>
          <div class="address one-hidden">
            {{ item.province }}{{ item.city }}{{ item.county }}{{ item.township }}{{ item.village }}{{ item.address }}
          </div>
        </div>
      </el-col>
    </el-row>
    <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="totalNum" @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { screenLandList, imageAll } from '@/api/api.js';
import * as utils from '@/utils/utils.js';
export default {
  data() {
    return {
      landList: [],
      pageNum: 1,
      pageSize: 8,
      totalNum: 0,
      loadError: false
    };
  },
  filters: {
    formatDate(val) {
      return val.substr(0, 10);
    }
  },
  created() {
    this.getLandList();
  },
  mounted() {},
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    // 获取地块列表
    getLandList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      screenLandList(params)
        .then((res) => {
          this.pageNum = res.page;
          this.totalNum = res.total;
          this.landList = res.rows;
        })
        .catch((error) => {});
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLandList();
    },
    imgError() {
      this.loadError = true;
    }
  }
};
</script>
<style scoped lang="scss">
.page-container {
  padding: 20px;
}
.add-land-btn-wrap {
  margin-bottom: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;

  .item-wrap {
    .img-wrap {
      width: 100%;
      .image-slot {
        width: 100%;
        height: 300px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f7fa;
        color: #909399;
      }
    }

    .content-wrap {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 15px;
      }

      .content-foot {
        color: #7e88a8;
        display: flex;
        justify-content: space-between;

        .align-center {
          display: flex;
          align-items: center;
        }

        img {
          height: 12px;
          margin-right: 3px;
        }
      }
    }
  }

  .address {
    height: 18px;
    line-height: 18px;
    color: #7e88a8;
  }
}

.el-pagination {
  text-align: center;
}

.one-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
