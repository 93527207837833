<template>
  <div class="fill-view" v-loading="loading">
    <iframe ref="Iframe" class="fill-view2" :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      src: '',
      loading: false
    };
  },

  watch: {
    $route: {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        this.getUrl();
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      if (this.$route.query.id) {
        const protpcol = 'https:';
        let id = this.$route.query.id;
        if (id == 1) {
          this.src = protpcol + '//pc.bjznyjny.com/szsw/shuizhi1.html';
        }
        if (id == 2) {
          this.src = protpcol + '//pc.bjznyjny.com/szsw/shuizhi2.html';
        }
        if (id == 3) {
          this.src = protpcol + '//pc.bjznyjny.com/szztpc/index.html';
        }
        if (id == 4) {
          this.src = protpcol + '//pc.bjznyjny.com/yaoganpc/index.html';
        }
        if (id == 5) {
          this.src = protpcol + '//pc.bjznyjny.com/szztpc/wurenji.html';
        }
      }
      this.iframeLoad();
    },
    iframeLoad() {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent('onload', () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    }
  }
};
</script>
<style scoped lang="scss">
.fill-view {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.fill-view2 {
  width: 100%;
  height: 100%;
}
</style>
